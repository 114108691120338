import './Form.scss';
import { useState , useRef } from 'react';
import emailjs from '@emailjs/browser';
import FormButton from './FormButton/FormButton';
import ALERT_ICON from '../../../assets/alert-icon.svg';

const Form = () => {
    const initialFormData = {
        name: '',
        email: '',
        message: ''
    };

    const initialErrorsData = {
        name: false,
        email: false,
        emptyEmail: false,
        message: false,
    };

    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState(initialErrorsData);
    const [firstSubmit, setFirstSubmit] = useState(true);

    const form = useRef();

    // Sending Message On Mail

    function sendEmail(e) {
        e.preventDefault();

        emailjs
            .sendForm('service_5sgj0vm', 'template_geconr4', form.current, {
                publicKey: 'jIhMiR4aDd2qdAm28',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };


    // Validation For Inputs

    const validateForm = (id, value) => {
        let newErrors = { ...errors };
    
        if (!id) {
            newErrors.name = !formData.name.trim();
            newErrors.email = formData.email.trim() ? !/[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/.test(formData.email) : false;
            newErrors.emptyEmail = !formData.email.trim();
            newErrors.message = !(formData.message.trim().length < 500);
        } else if (id === 'name') {
            newErrors.name = !value.trim();
        } else if (id === 'email') {
            newErrors.email = !/[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/.test(value ?? '');
            newErrors.emptyEmail = !value?.trim();
        } else if (id === 'message') {
            newErrors.message = !((value ?? '').trim()?.length < 500);
        }
        
        setErrors(newErrors);
    
        return newErrors;
    };

    // Changing On Typing In Inputs

    const handleChange = (e) => {
        const {id, value} = e.target;

        setFormData({
            ...formData, [id] : value
        });

        if (!firstSubmit) {
            validateForm(id, value);
        }
    }

     // Submiting Form

     const handleSubmit = (e) => {
        e.preventDefault();

        if (firstSubmit) {
            setFirstSubmit(false);
        }

        let newErrors = validateForm();
    
        if(JSON.stringify(Object.values(newErrors)) === JSON.stringify(Object.values(initialErrorsData))) {
            sendEmail(e);
            setFirstSubmit(false);
            setFormData(initialFormData);
            e.target.reset();
        }
    };


    return (
        <form onSubmit={handleSubmit} id='form' ref={form}>
            <span className='form-title'>Fill out the form and we will contact you to create the best solution based on your needs</span>

            <div className='input-field'>
                <input 
                    type='text' 
                    placeholder='Name' 
                    id='name' 
                    className={`input-item ${errors.name && 'error'}`}
                    autoComplete='off'
                    onChange={handleChange}
                    name="user_name"
                />
                <div className={`err-txt ${errors.name && 'error'}`}>This field is required</div>

                <img className={`err-icon ${errors.name && 'error'}`} src={ALERT_ICON} alt={'alert'}/>
            </div>

            <div className='input-field'>
                <input 
                    type='text' 
                    placeholder='Email' 
                    id='email' 
                    className={`input-item ${(errors.email || errors.emptyEmail) && 'error'}`}
                    autoComplete='off'
                    onChange={handleChange}
                    name="user_email"
                />
                <div className={`err-txt ${(errors.email || errors.emptyEmail) && 'error'}`}>
                    {
                        errors.email ? (<>email is incorrect</>)
                            : errors.emptyEmail ? (<>This field is required</>)
                            : null
                    }
                </div>

                <img className={`err-icon ${(errors.email || errors.emptyEmail) && 'error'}`} src={ALERT_ICON} alt={'alert'}/>
            </div>

            <div className='input-field textarea'>
                <textarea
                    id='message'
                    maxLength={500}
                    placeholder='Message'
                    className={`input-item ${errors.message && 'error'}`}
                    autoComplete='off'
                    onChange={handleChange}
                    name="user_message"
                />
                <div className={`err-txt ${errors.message && 'error'}`}>You have reached 500 characters</div>
            </div>


            <p className='form-add-text'>Sending the message you agree to the Privacy Policy</p>
            <div className='form-button-container'>
                <FormButton/>
            </div>
        </form>
    )
}

export default Form
